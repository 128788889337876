import React, {useEffect, useState} from 'react';
import {Accordion, Button, Col, Form, Pagination, Row, Table} from 'react-bootstrap';
import {AuditLog, AuditLogApiFindAuditLogsRequest} from '../../api/audit-service-api';
import {OrderDirection} from '../../api/auth-service-api';
import {auditLogApi} from '../../api/apis.configuration';
import {PAGE_SIZE} from '../../constants/page.constants';
import {showToast} from '../../services/toast.service';
import {CaretDownFill, CaretUpFill} from 'react-bootstrap-icons';
import {downloadZipFile} from '../../utils/file.utils';
import {toNumber} from 'lodash';
import moment from 'moment';

const AuditLogList: React.FC = () => {
  const initialFilter: Partial<AuditLogApiFindAuditLogsRequest> = {};

  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [filter, setFilter] = useState<AuditLogApiFindAuditLogsRequest>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [totalItems, setTotalItems] = useState(0);
  const [orderBy, setOrderBy] = useState<string | undefined>('createdDate');
  const [orderDirection, setOrderDirection] = useState<OrderDirection>('ASC');

  useEffect(() => {
    fetchAuditLogs();
  }, [currentPage, pageSize, orderBy, orderDirection,auditLogs]);

  const fetchAuditLogs = async () => {
    try {
      const response = await auditLogApi.findAuditLogs({
        ...validateFilter(filter),
        page: currentPage - 1,
        pageSize,
        orderBy,
        orderDirection,
      });
      const data = Array.isArray(response.data) ? response.data : [];
      setAuditLogs(data);
      const totalCount = parseInt(response.headers['x-total-count'], 10);
      setTotalItems(totalCount);
    } catch (error) {
      showToast('danger', 'Error fetching audit logs');
    }
  };

  const validateFilter = (filter: any) => {
    const clonedFilter = {...filter};
    if (clonedFilter.createdDateStart) {
      clonedFilter.createdDateStart = moment(clonedFilter.createdDateStart + ':00').toISOString();
    }
    if (clonedFilter.createdDateEnd) {
      clonedFilter.createdDateEnd = moment(clonedFilter.createdDateEnd + ':59').toISOString();
    }
    return clonedFilter;
  }

  const downloadFilteredLogs = async () => {
    try {
      auditLogApi.createAuditLogExport({
        ...validateFilter(filter),
        orderBy,
        orderDirection,
      }).then((response) => {
          if (response.data.fileId) {
            downloadZipFile(response.data.fileId);
          }
        }
      )
    } catch (error:any) {
      showToast('danger', error.response.data.fieldErrors);
    }
  }

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };


  const handlePageSizeChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const handleSort = (field: string) => {
    const direction = orderBy === field && orderDirection === 'ASC' ? 'DESC' : 'ASC';
    setOrderBy(field);
    setOrderDirection(direction);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleIdArrayChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target;

    const valuesArray = value.length ? value.split(',')?.map((item) => {
      const id = toNumber(item.trim());
      return isNaN(id) ? undefined : id;
    }) : undefined;

    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: valuesArray,
    }));
  };

  const handleClearFilters = () => {
    setFilter(initialFilter);
    setCurrentPage(1);
  };

  const renderSortIcon = (field: string) => {
    if (orderBy === field) {
      return orderDirection === 'ASC' ? (
        <CaretUpFill/>
      ) : (
        <CaretDownFill/>
      );
    }
    return null;
  };

  const pagination = () => {
    const totalPages = Math.ceil(totalItems / pageSize);
    let items = [];

    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  return (
    <div className="my-5">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Logiraamat</h3>
        <Button variant="primary" onClick={downloadFilteredLogs}>
          Lae alla filtreeritud logid
        </Button>
      </div>

      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filter</Accordion.Header>
          <Accordion.Body>
            <Form>
              <Row>
                <Form.Group as={Col} controlId="formId">
                  <Form.Label>ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="id"
                    placeholder="Komaga eraldatud logide ID-d"
                    value={filter.id?.join(',') || ''}
                    onChange={handleIdArrayChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formEventType">
                  <Form.Label>Sündmuse tüüp</Form.Label>
                  <Form.Control
                    type="text"
                    name="eventType"
                    value={filter.eventType || ''}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="formCreatedDateStart">
                  <Form.Label>Loomise kuupäev algus</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="createdDateStart"
                    value={filter.createdDateStart || ''}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formCreatedDateEnd">
                  <Form.Label>Loomise kuupäev lõpp</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="createdDateEnd"
                    value={filter.createdDateEnd || ''}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="formEntityType">
                  <Form.Label>Olemi tüüp</Form.Label>
                  <Form.Control
                    type="text"
                    name="entityType"
                    value={filter.entityType || ''}
                    onChange={handleFilterChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formEntityId">
                  <Form.Label>Olemi ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="entityId"
                    placeholder="Komaga eraldatud olemite ID-d"
                    value={filter.entityId?.join(',') || ''}
                    onChange={handleIdArrayChange}
                  />
                </Form.Group>
              </Row>
              <Button variant="primary" onClick={fetchAuditLogs} className="mt-2">
                Rakenda filtrid
              </Button>
              <Button variant="secondary" onClick={handleClearFilters} className="mt-2 ms-2">
                Puhasta filtrid
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>


      <Table striped bordered hover className="mt-3">
        <thead>
        <tr>
          <th onClick={() => handleSort('id')}>ID {renderSortIcon('id')}</th>
          <th onClick={() => handleSort('createdDate')}>Loomise kuupäev/kellaaeg {renderSortIcon('createdDate')}</th>
          <th onClick={() => handleSort('createdBy')}>Sisestaja {renderSortIcon('createdBy')}</th>
          <th onClick={() => handleSort('eventType')}>Sündmuse  tüüp{renderSortIcon('eventType')}</th>
          <th onClick={() => handleSort('entityId')}>Olemi ID {renderSortIcon('entityId')}</th>
          <th onClick={() => handleSort('entityType')}>Olemi tüüp {renderSortIcon('entityType')}</th>
          <th>Muudatused</th>
        </tr>
        </thead>
        <tbody>
        {auditLogs.map((log) => (
          <tr key={log.id}>
            <td>{log.id}</td>
            <td>{log.createdDate}</td>
            <td>{log.createdBy}</td>
            <td>{log.eventType}</td>
            <td>{log.entityId}</td>
            <td>{log.entityType}</td>
            <td>
              <ul>
                {log.changes?.map((change: any, index: any) => (
                  <li key={index}>
                    {change.propertyName}: {JSON.stringify(change.oldValue)} → {JSON.stringify(change.newValue)}
                  </li>
                ))}
              </ul>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between align-items-center mt-3">
        <Pagination>
          {pagination()}
        </Pagination>
        <Form.Group controlId="pageSizeSelect">
          <Form.Label>Kirjete arv lehel</Form.Label>
          <Form.Control
            as="select"
            name="pageSize"
            value={pageSize}
            onChange={handlePageSizeChange}
            className="pageSizeSelect"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </Form.Control>
        </Form.Group>
      </div>
    </div>
  );
};

export default AuditLogList;
