export type AlertType = 'success' | 'danger' | 'warning' | 'info';

export const showAlert = (message: string, type: AlertType = 'danger') => {
  const id = new Date().getTime();
  (window as any).showAlert({ id, type, message });
};

export const handleApiError = (error: any, isGlobalCheck = false) => {
  if (isGlobalCheck) {
    showAlert('The API is currently unreachable. Please try again later.', 'danger');
  } else {
    showAlert('Failed to communicate with the server. Please try again.', 'danger');
  }
};
