import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {User} from '../../../api/auth-service-api';
import {userApi} from '../../../api/apis.configuration';


interface DeleteUserModalProps {
  show: boolean;
  handleClose: () => void;
  userToDelete: User | null;
  handleUserDeleted: () => void;
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({show, handleClose, userToDelete, handleUserDeleted}) => {
  const handleDelete = async () => {
    if (userToDelete) {
      try {
        await userApi.deleteUser({id: userToDelete.id!});
        handleUserDeleted();
        handleClose();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Kasutaja kustutamine</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Kas soovid kindlasti kustutada kasutaja {userToDelete?.login}?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Katkesta
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Kustuta
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteUserModal;
