import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {User} from '../../../api/auth-service-api';
import {userApi} from '../../../api/apis.configuration';
import {DEFAULT_LANGUAGE} from '../../../constants/languuage.constants';
import {showToast} from '../../../services/toast.service';

interface CreateUserModalProps {
  show: boolean;
  handleClose: () => void;
  handleUserCreatedOrUpdated: (user: User | null) => void;
  isEdit?: boolean;
  userToEdit?: User | null;
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({
                                                           show,
                                                           handleClose,
                                                           handleUserCreatedOrUpdated,
                                                           isEdit = false,
                                                           userToEdit = null
                                                         }) => {
  const [login, setLogin] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [langKey, setLangKey] = useState(DEFAULT_LANGUAGE);
  const [authorities, setAuthorities] = useState<Array<string>>([]);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (isEdit && userToEdit) {
      setLogin(userToEdit.login);
      setFirstName(userToEdit.firstName || '');
      setLastName(userToEdit.lastName || '');
      setEmail(userToEdit.email || '');
      setLangKey(userToEdit.langKey || DEFAULT_LANGUAGE);
      setAuthorities(authorities || []);
    } else {
      resetForm();
    }
  }, [isEdit, userToEdit]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    try {
      if (isEdit && userToEdit) {
        const updatedUser: User = {
          id: userToEdit.id,
          login,
          firstName,
          lastName,
          email,
          langKey,
          authorities
        };
        await userApi.updateUser({id: userToEdit.id!, user: updatedUser}).then(() => {
          showToast('success', 'Kasutaja uuendatud edukalt!');
          close(null);
        }).catch((error) => {
          showToast('danger', 'Kasutaja uuendamine ebaõnnestus: ' + JSON.stringify(error.response.data.fieldErrors));
        });
      } else {
        const newUser: User = {
          login,
          firstName,
          lastName,
          email,
          langKey,
          authorities
        };
        await userApi.createUser({createUserRequest: newUser}).then((response) => {
          showToast('success', 'Kasutaja loodud edukalt!');
          close(response.data);
        }).catch((error) => {
          showToast('danger', 'Kasutaja loomine ebaõnnestus: ' + JSON.stringify(error.response.data.fieldErrors));
        });

      }
    } catch (error) {
      showToast('danger', 'Tegevus ebaõnnestus!');
    }
  };

  const close = (user: User | null) => {
    handleUserCreatedOrUpdated(user);
    handleClose();
    resetForm();
  }

  const resetForm = () => {
    setLogin('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setLangKey(DEFAULT_LANGUAGE);
    setAuthorities(authorities || []);
    setValidated(false);
  };

  const handleAuthoritiesChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const selectedOptions = (Array.of(e.target.value));
    setAuthorities(selectedOptions);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Kasutaja muutmine' : 'Kasutaja loomine'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="formLogin">
            <Form.Label>Kasutaja nimi</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Sisestage kasutajanimi"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Kasutja ei ole unikaalne.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formFirstName" className="mt-3">
            <Form.Label>Eesnimi</Form.Label>
            <Form.Control
              type="text"
              placeholder="Sisestage eesnimi"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formLastName" className="mt-3">
            <Form.Label>Perekonnanimi</Form.Label>
            <Form.Control
              type="text"
              placeholder="Sisestage perekonnanimi"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formEmail" className="mt-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Sisestage email"
              value={email}
              required
              min={5}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formLangKey" className="mt-3">
            <Form.Label>Keel</Form.Label>
            <Form.Control
              as="select"
              value={langKey}
              onChange={(e) => setLangKey(e.target.value)}
            >
              <option value="et">Eesti</option>
              <option value="en">Inglise</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formRoles" className="mt-3">
            <Form.Label>Rollid</Form.Label>
            <Form.Control
              as="select"
              multiple
              value={Array.from(authorities)}
              onChange={handleAuthoritiesChange}
            >
              <option value="ROLE_ADMIN">Admin</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Palun valige vähemalt üks roll.
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-4">
            {isEdit ? 'Kasutaja uuendamine' : 'Kasutaja loomine'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserModal;
