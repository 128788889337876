import React from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Card className="mt-5 p-2">
        <Card.Body>
          <Card.Title>{t('homePage.title')}</Card.Title>
          <div>
            <Row className="mb-2">
              <Col>
                <strong>1.1.</strong> {t('homePage.chapter1')}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <strong>1.2.</strong> {t('homePage.chapter2')}
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default HomePage;
