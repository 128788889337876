import {LoginApi, UserApi} from './auth-service-api';
import authServiceApiConfig from './auth-service-api/auth-service-api-config';
import axiosInstance from './axios.instance';
import {AccountApi} from './gw-api';
import {NotificationApi} from './notification-service-api';
import notificationApiConfig from './notification-service-api/notification-api-config';
import {AuditLogApi} from './audit-service-api';
import auditLogApiConfig from './audit-service-api/audit-api-config';

export const loginApi = new LoginApi(authServiceApiConfig, '', axiosInstance);
export const accountApi = new AccountApi(authServiceApiConfig, '', axiosInstance);
export const notificationApi = new NotificationApi(notificationApiConfig, '', axiosInstance);
export const auditLogApi = new AuditLogApi(auditLogApiConfig, '', axiosInstance);
export const userApi = new UserApi(authServiceApiConfig, '', axiosInstance);
