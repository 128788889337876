import React, {useEffect, useState} from 'react';
import {Alert} from 'react-bootstrap';
import './AlertContainer.css';
import {AlertType} from '../../services/notification.service';
import uuid from 'uuid-random';
interface AlertMessage {
  id: string;
  type: AlertType;
  message: string;
}

const AlertContainer: React.FC = () => {
  const [alerts, setAlerts] = useState<AlertMessage[]>([]);

  useEffect(() => {
    const showAlert = (alert: AlertMessage) => {
      setAlerts((prevAlerts) => {
        prevAlerts = prevAlerts.filter((a) => a.message !== alert.message);
        alert.id = uuid();
        return [...prevAlerts, alert]
      });
    };
    (window as any).showAlert = showAlert;
  }, []);

  const handleDismiss = (id: string) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  };

  return (
    <div className="alert-container">
      {alerts.map((alert) => (
        <div key={alert.id} className="alert-wrapper">
          <Alert className="alert-custom"
                 variant={alert.type}
                 dismissible
                 onClose={() => handleDismiss(alert.id)}
          >
            {alert.message}
          </Alert>
        </div>
      ))}
    </div>
  );
};

export default AlertContainer;
