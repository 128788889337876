import React from 'react';
import {Route, Routes} from 'react-router';
import {Container} from 'react-bootstrap';

import Login from '../login/Login';
import {
  ROUTE_AUDIT_LOGS,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_NOTIFICATIONS,
  ROUTE_USERS
} from '../../constants/route.constants';
import HomePage from '../../pages/HomePage';
import UserList from '../users/UserList';
import NotificationList from '../notifications/NotificationList';
import AuditLogList from '../auditlogs/AuditLogList';

interface MainContentProps {
  isAuthenticated: boolean;
}

const MainContent: React.FC<MainContentProps> = ({isAuthenticated}) => {

  return (
    <Container>
      <Routes>
        <Route path={ROUTE_HOME} element={<HomePage/>}/>
        <Route path={ROUTE_USERS} element={<UserList/>}/>
        <Route path={ROUTE_NOTIFICATIONS} element={<NotificationList/>}/>
        <Route path={ROUTE_AUDIT_LOGS} element={<AuditLogList/>}/>
        {!isAuthenticated ? (<Route path={ROUTE_LOGIN} element={<Login/>}/>) : null}
      </Routes>
    </Container>
  );
};

export default MainContent;
