import React from 'react';
import {Container, Nav, Navbar} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import AuthService from '../../services/auth.service';
import {STORAGE_USER_KEY} from '../../constants/storage.constants';
import {User} from '../../api/auth-service-api';
import {
  ROUTE_AUDIT_LOGS,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_NOTIFICATIONS,
  ROUTE_USERS
} from '../../constants/route.constants';

interface MedreNavbarProps {
  isAuthenticated: boolean;
}

const MedreNavbar: React.FC<MedreNavbarProps> = ({isAuthenticated}) => {
  const {t} = useTranslation();
  const account: User = JSON.parse(sessionStorage.getItem(STORAGE_USER_KEY)!);

  const logout = () => {
    AuthService.logout();
  }

  return (
    <>
      <Navbar bg="light" variant={'light'} expand="lg">
        <Container>
          <Navbar.Brand href={ROUTE_HOME}>{t('nav.appName')}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {!isAuthenticated && (
                <Nav.Link href={ROUTE_NOTIFICATIONS}>{t('nav.sendNotification')}</Nav.Link>)
              }
              {isAuthenticated && (
                <Nav.Link href={ROUTE_USERS}>{t('nav.users')}</Nav.Link>)
              }
              {isAuthenticated && (
                <Nav.Link href={ROUTE_NOTIFICATIONS}>{t('nav.notifications')}</Nav.Link>)
              }
              {isAuthenticated && (
                <Nav.Link href={ROUTE_AUDIT_LOGS}>{t('nav.auditLogs')}</Nav.Link>)
              }
            </Nav>
            <Nav>
              <Navbar.Text><strong>{account?.firstName} {account?.lastName}</strong>
              </Navbar.Text>
              {!isAuthenticated && (
                <Nav className="me-auto">
                  <Nav.Link href={ROUTE_LOGIN}>{t('nav.login')}</Nav.Link>
                </Nav>
              )}
              {isAuthenticated && (
                <Nav.Link onClick={logout}>{t('nav.logout')}</Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default MedreNavbar;
