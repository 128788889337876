import React, {useEffect, useState} from 'react';
import {Alert, Button, Pagination, Table} from 'react-bootstrap';
import CreateUserModal from './modals/CreateUserModal';
import DeleteUserModal from './modals/DeleteUserModal';
import {User} from '../../api/auth-service-api';
import {userApi} from '../../api/apis.configuration';
import {PAGE_SIZE} from '../../constants/page.constants';

const UserList: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newUser, setNewUser] = useState<User | null>(null);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);

  const pageSize = PAGE_SIZE;

  useEffect(() => {
    fetchUsers(currentPage).then(r => r);
  }, [currentPage]);


  const fetchUsers = async (page: number) => {
    try {
      const response = await userApi.findUsers({page: page - 1,});
      setUsers(response.data);

      const totalCount = parseInt(response.headers['x-total-count'], 10);
      const totalPages = Math.ceil(totalCount / pageSize);

      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleModalClose = () => {
    setShowCreateModal(false);
    setIsEdit(false);
    setUserToEdit(null);
  }

  const handleUserCreatedOrUpdated = (user: User | null) => {
    setNewUser(user);
    fetchUsers(currentPage);
  };

  const handleUserDeleted = () => {
    fetchUsers(currentPage);
  };

  const pagination = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>
      );
    }
    return <Pagination>{items}</Pagination>;
  };

  const newUserIsCreated = (newUser: User | null) => {
    if (newUser) {
      return (
        <Alert variant="info" onClose={() => setNewUser(null)} dismissible>
          <Alert.Heading>Uus kasutaja on loodud!</Alert.Heading>
          <ul>
            <li>Kasutajanimi:<strong>{newUser.login}</strong></li>
            <li>Parool:<strong>{(newUser as any)['password']}</strong></li>
          </ul>
        </Alert>
      );
    }
    return null;
  }

  return (
    <div className="my-5">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Kasutajad</h3>
        <Button variant="primary" onClick={() => setShowCreateModal(true)}>
          Lisa uus kasutaja
        </Button>
      </div>

      <div>
        {newUserIsCreated(newUser)}
      </div>

      <Table striped bordered hover>
        <thead>
        <tr>
          <th>ID</th>
          <th>Login</th>
          <th>Eesnimi</th>
          <th>Perekonnanimi</th>
          <th>Email</th>
          <th>Keel</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {users.map((user) => (
          <tr key={user.id}>
            <td>{user.id}</td>
            <td>{user.login}</td>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.email}</td>
            <td>{user.langKey}</td>
            <td>
              <Button
                variant="warning"
                size="sm"
                onClick={() => {
                  setUserToEdit(user);
                  setIsEdit(true);
                  setShowCreateModal(true);
                }}
                className="mx-1"
              >
                Muuda
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => {
                  setUserToDelete(user);
                  setShowDeleteModal(true);
                }}
                className="mx-1"
              >
                Kustuta
              </Button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-center">
        {pagination()}
      </div>

      <CreateUserModal
        show={showCreateModal}
        handleClose={handleModalClose}
        handleUserCreatedOrUpdated={handleUserCreatedOrUpdated}
        isEdit={isEdit}
        userToEdit={userToEdit}
      />

      <DeleteUserModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        userToDelete={userToDelete}
        handleUserDeleted={handleUserDeleted}
      />
    </div>
  );
};

export default UserList;
