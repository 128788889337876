import {accountApi} from '../api/apis.configuration';
import {STORAGE_USER_KEY} from '../constants/storage.constants';


class AccountService {
  getUserAccount(): Promise<any> {
    return accountApi.getAccount().then(response => {
      sessionStorage.setItem(STORAGE_USER_KEY, JSON.stringify(response.data));
      return response.data
    });
  }
}

const accountService = new AccountService();

export default accountService;
