import axios from 'axios';
import {STORAGE_API_KEY} from '../constants/storage.constants';
import {ROUTE_LOGIN} from '../constants/route.constants';
import authService from '../services/auth.service';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem(STORAGE_API_KEY);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;


axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      console.error('Unauthorized! Redirecting to login...');
      authService.logout();
      window.location.href = ROUTE_LOGIN;
    } else if (error.response?.status === 500) {
      console.error('Server error:', error.response.data.message);
    } else {
      console.error('Error response:', error.response);
    }

    return Promise.reject(error);
  }
);
