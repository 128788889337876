import React, {useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import './App.css';
import MedreNavbar from './components/layout/MedreNavbar';
import MainContent from './components/layout/MainContent';
import MedreFooter from './components/layout/MedreFooter';
import ToastContainer from './components/toasts/ToastContainer';
import AlertContainer from './components/alerts/AlertContainer';
import {checkApiStatus} from './services/api.service';
import authService from './services/auth.service';

function App() {

  const isAuthenticated = authService.isAuthenticated();

  useEffect(() => {
    authService.getCurrentUser();
    checkApiStatus().catch(() => {
    });
  }, []);

  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <AlertContainer/>
        <ToastContainer/>
        <header>
          <MedreNavbar isAuthenticated={isAuthenticated}/>
        </header>
        <main className="flex-grow-1">
          <MainContent isAuthenticated={isAuthenticated}/>
        </main>
        <footer className="bg-light text-black text-center py-3">
          <MedreFooter/>
        </footer>
      </div>
    </Router>
  );
}

export default App;
