import {InfoApi, type ServiceInfo} from '../api/gw-api';
import gwApiConfig from '../api/gw-api/gw-api-config';
import {handleApiError, showAlert} from './notification.service';

export const apiRequest = async (url: string, options: RequestInit = {}) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }
    return response.json();
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const checkApiStatus = async () => {
  const infoApi = new InfoApi(gwApiConfig);
  infoApi.info().then((response) => {
    response.data.services?.forEach((service: ServiceInfo) => {
      if (service.status !== 'UP') {
        showServiceUnavailableAlert(service);
      }
    });
  }).catch((error) => {
    handleApiError(error, true);
  });
};

export const showServiceUnavailableAlert = (service: ServiceInfo) => {
  showAlert(`Service ${service.name} is unavailable!`, 'danger');
}
