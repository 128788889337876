import React, {useState} from 'react';
import {Alert, Button, Col, Container, Form, Row} from 'react-bootstrap';
import authService from '../../services/auth.service';
import {useTranslation} from 'react-i18next';

const Login: React.FC = () => {
  const {t} = useTranslation();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(undefined);

    try {
      authService.login(username, password).catch((error) => {
        setErrorMessage(t('login.invalidCredentials'));
      });
    } catch (error) {
      setErrorMessage(t('login.invalidCredentials'));
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={4}>
          <h2 className="text-center mb-3">{t('login.title')}</h2>
          {errorMessage && (
            <Alert variant="danger" role="alert">
              {errorMessage}
            </Alert>
          )}

          <Form onSubmit={handleLogin}>
            <Form.Group controlId="username">
              <Form.Label>{t('login.username')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('login.username')}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="password" className="mt-3">
              <Form.Label>{t('login.password')}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t('login.password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Row className="mt-4">
              <Col>
                <Button type="submit" variant="primary">
                  {t('login.loginButton')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
