import React from 'react';
import {useTranslation} from 'react-i18next';

const MedreFooter: React.FC = () => {
  const {t} = useTranslation();

  return (
    <div className="text-center">{t('footer.title')}</div>
  );
};

export default MedreFooter;
