/* tslint:disable */
/* eslint-disable */
/**
 * Audit Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AuditLog } from '../model';
// @ts-ignore
import type { ExportResult } from '../model';
// @ts-ignore
import type { OrderDirection } from '../model';
// @ts-ignore
import type { Problem } from '../model';
/**
 * AuditLogApi - axios parameter creator
 * @export
 */
export const AuditLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuditLog} [auditLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuditLog: async (auditLog?: AuditLog, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/audit-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditLog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [id] 
         * @param {string} [eventType] 
         * @param {string} [createdDateStart] 
         * @param {string} [createdDateEnd] 
         * @param {string} [entityType] 
         * @param {Array<string>} [entityId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {OrderDirection} [orderDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuditLogExport: async (id?: Array<number>, eventType?: string, createdDateStart?: string, createdDateEnd?: string, entityType?: string, entityId?: Array<string>, page?: number, pageSize?: number, orderBy?: string, orderDirection?: OrderDirection, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/audit-log/export/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }

            if (createdDateStart !== undefined) {
                localVarQueryParameter['createdDateStart'] = (createdDateStart as any instanceof Date) ?
                    (createdDateStart as any).toISOString() :
                    createdDateStart;
            }

            if (createdDateEnd !== undefined) {
                localVarQueryParameter['createdDateEnd'] = (createdDateEnd as any instanceof Date) ?
                    (createdDateEnd as any).toISOString() :
                    createdDateEnd;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (entityId) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDirection !== undefined) {
                localVarQueryParameter['orderDirection'] = orderDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuditLogExport: async (fileId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadAuditLogExport', 'fileId', fileId)
            const localVarPath = `/api/v1/audit-log/export/download/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [id] 
         * @param {string} [eventType] 
         * @param {string} [createdDateStart] 
         * @param {string} [createdDateEnd] 
         * @param {string} [entityType] 
         * @param {Array<string>} [entityId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {OrderDirection} [orderDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuditLogs: async (id?: Array<number>, eventType?: string, createdDateStart?: string, createdDateEnd?: string, entityType?: string, entityId?: Array<string>, page?: number, pageSize?: number, orderBy?: string, orderDirection?: OrderDirection, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/audit-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }

            if (createdDateStart !== undefined) {
                localVarQueryParameter['createdDateStart'] = (createdDateStart as any instanceof Date) ?
                    (createdDateStart as any).toISOString() :
                    createdDateStart;
            }

            if (createdDateEnd !== undefined) {
                localVarQueryParameter['createdDateEnd'] = (createdDateEnd as any instanceof Date) ?
                    (createdDateEnd as any).toISOString() :
                    createdDateEnd;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entityType'] = entityType;
            }

            if (entityId) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDirection !== undefined) {
                localVarQueryParameter['orderDirection'] = orderDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLog: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAuditLog', 'id', id)
            const localVarPath = `/api/v1/audit-log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditLogApi - functional programming interface
 * @export
 */
export const AuditLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AuditLog} [auditLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuditLog(auditLog?: AuditLog, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuditLog(auditLog, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditLogApi.createAuditLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<number>} [id] 
         * @param {string} [eventType] 
         * @param {string} [createdDateStart] 
         * @param {string} [createdDateEnd] 
         * @param {string} [entityType] 
         * @param {Array<string>} [entityId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {OrderDirection} [orderDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuditLogExport(id?: Array<number>, eventType?: string, createdDateStart?: string, createdDateEnd?: string, entityType?: string, entityId?: Array<string>, page?: number, pageSize?: number, orderBy?: string, orderDirection?: OrderDirection, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuditLogExport(id, eventType, createdDateStart, createdDateEnd, entityType, entityId, page, pageSize, orderBy, orderDirection, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditLogApi.createAuditLogExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAuditLogExport(fileId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAuditLogExport(fileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditLogApi.downloadAuditLogExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<number>} [id] 
         * @param {string} [eventType] 
         * @param {string} [createdDateStart] 
         * @param {string} [createdDateEnd] 
         * @param {string} [entityType] 
         * @param {Array<string>} [entityId] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {OrderDirection} [orderDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAuditLogs(id?: Array<number>, eventType?: string, createdDateStart?: string, createdDateEnd?: string, entityType?: string, entityId?: Array<string>, page?: number, pageSize?: number, orderBy?: string, orderDirection?: OrderDirection, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAuditLogs(id, eventType, createdDateStart, createdDateEnd, entityType, entityId, page, pageSize, orderBy, orderDirection, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditLogApi.findAuditLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditLog(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditLog(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuditLogApi.getAuditLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuditLogApi - factory interface
 * @export
 */
export const AuditLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditLogApiFp(configuration)
    return {
        /**
         * 
         * @param {AuditLogApiCreateAuditLogRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuditLog(requestParameters: AuditLogApiCreateAuditLogRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AuditLog> {
            return localVarFp.createAuditLog(requestParameters.auditLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuditLogApiCreateAuditLogExportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuditLogExport(requestParameters: AuditLogApiCreateAuditLogExportRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ExportResult> {
            return localVarFp.createAuditLogExport(requestParameters.id, requestParameters.eventType, requestParameters.createdDateStart, requestParameters.createdDateEnd, requestParameters.entityType, requestParameters.entityId, requestParameters.page, requestParameters.pageSize, requestParameters.orderBy, requestParameters.orderDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuditLogApiDownloadAuditLogExportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuditLogExport(requestParameters: AuditLogApiDownloadAuditLogExportRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.downloadAuditLogExport(requestParameters.fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuditLogApiFindAuditLogsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAuditLogs(requestParameters: AuditLogApiFindAuditLogsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<AuditLog>> {
            return localVarFp.findAuditLogs(requestParameters.id, requestParameters.eventType, requestParameters.createdDateStart, requestParameters.createdDateEnd, requestParameters.entityType, requestParameters.entityId, requestParameters.page, requestParameters.pageSize, requestParameters.orderBy, requestParameters.orderDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuditLogApiGetAuditLogRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLog(requestParameters: AuditLogApiGetAuditLogRequest, options?: RawAxiosRequestConfig): AxiosPromise<AuditLog> {
            return localVarFp.getAuditLog(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAuditLog operation in AuditLogApi.
 * @export
 * @interface AuditLogApiCreateAuditLogRequest
 */
export interface AuditLogApiCreateAuditLogRequest {
    /**
     * 
     * @type {AuditLog}
     * @memberof AuditLogApiCreateAuditLog
     */
    readonly auditLog?: AuditLog
}

/**
 * Request parameters for createAuditLogExport operation in AuditLogApi.
 * @export
 * @interface AuditLogApiCreateAuditLogExportRequest
 */
export interface AuditLogApiCreateAuditLogExportRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AuditLogApiCreateAuditLogExport
     */
    readonly id?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiCreateAuditLogExport
     */
    readonly eventType?: string

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiCreateAuditLogExport
     */
    readonly createdDateStart?: string

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiCreateAuditLogExport
     */
    readonly createdDateEnd?: string

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiCreateAuditLogExport
     */
    readonly entityType?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditLogApiCreateAuditLogExport
     */
    readonly entityId?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof AuditLogApiCreateAuditLogExport
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AuditLogApiCreateAuditLogExport
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiCreateAuditLogExport
     */
    readonly orderBy?: string

    /**
     * 
     * @type {OrderDirection}
     * @memberof AuditLogApiCreateAuditLogExport
     */
    readonly orderDirection?: OrderDirection
}

/**
 * Request parameters for downloadAuditLogExport operation in AuditLogApi.
 * @export
 * @interface AuditLogApiDownloadAuditLogExportRequest
 */
export interface AuditLogApiDownloadAuditLogExportRequest {
    /**
     * 
     * @type {string}
     * @memberof AuditLogApiDownloadAuditLogExport
     */
    readonly fileId: string
}

/**
 * Request parameters for findAuditLogs operation in AuditLogApi.
 * @export
 * @interface AuditLogApiFindAuditLogsRequest
 */
export interface AuditLogApiFindAuditLogsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AuditLogApiFindAuditLogs
     */
    readonly id?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiFindAuditLogs
     */
    readonly eventType?: string

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiFindAuditLogs
     */
    readonly createdDateStart?: string

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiFindAuditLogs
     */
    readonly createdDateEnd?: string

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiFindAuditLogs
     */
    readonly entityType?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof AuditLogApiFindAuditLogs
     */
    readonly entityId?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof AuditLogApiFindAuditLogs
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AuditLogApiFindAuditLogs
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof AuditLogApiFindAuditLogs
     */
    readonly orderBy?: string

    /**
     * 
     * @type {OrderDirection}
     * @memberof AuditLogApiFindAuditLogs
     */
    readonly orderDirection?: OrderDirection
}

/**
 * Request parameters for getAuditLog operation in AuditLogApi.
 * @export
 * @interface AuditLogApiGetAuditLogRequest
 */
export interface AuditLogApiGetAuditLogRequest {
    /**
     * 
     * @type {number}
     * @memberof AuditLogApiGetAuditLog
     */
    readonly id: number
}

/**
 * AuditLogApi - object-oriented interface
 * @export
 * @class AuditLogApi
 * @extends {BaseAPI}
 */
export class AuditLogApi extends BaseAPI {
    /**
     * 
     * @param {AuditLogApiCreateAuditLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public createAuditLog(requestParameters: AuditLogApiCreateAuditLogRequest = {}, options?: RawAxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).createAuditLog(requestParameters.auditLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuditLogApiCreateAuditLogExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public createAuditLogExport(requestParameters: AuditLogApiCreateAuditLogExportRequest = {}, options?: RawAxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).createAuditLogExport(requestParameters.id, requestParameters.eventType, requestParameters.createdDateStart, requestParameters.createdDateEnd, requestParameters.entityType, requestParameters.entityId, requestParameters.page, requestParameters.pageSize, requestParameters.orderBy, requestParameters.orderDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuditLogApiDownloadAuditLogExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public downloadAuditLogExport(requestParameters: AuditLogApiDownloadAuditLogExportRequest, options?: RawAxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).downloadAuditLogExport(requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuditLogApiFindAuditLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public findAuditLogs(requestParameters: AuditLogApiFindAuditLogsRequest = {}, options?: RawAxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).findAuditLogs(requestParameters.id, requestParameters.eventType, requestParameters.createdDateStart, requestParameters.createdDateEnd, requestParameters.entityType, requestParameters.entityId, requestParameters.page, requestParameters.pageSize, requestParameters.orderBy, requestParameters.orderDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuditLogApiGetAuditLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public getAuditLog(requestParameters: AuditLogApiGetAuditLogRequest, options?: RawAxiosRequestConfig) {
        return AuditLogApiFp(this.configuration).getAuditLog(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

