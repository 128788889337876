import React, {useEffect, useState} from 'react';
import {Toast, ToastContainer as BootstrapToastContainer} from 'react-bootstrap';

interface ToastMessage {
  id: number;
  type: 'success' | 'danger' | 'warning' | 'info';
  message: string;
}

const ToastContainer: React.FC = () => {
  const [toasts, setToasts] = useState<ToastMessage[]>([]);

  useEffect(() => {
    const showToast = (toast: ToastMessage) => {
      setToasts((prevToasts) => [...prevToasts, toast]);

      setTimeout(() => {
        setToasts((prevToasts) => prevToasts.filter((t) => t.id !== toast.id));
      }, 5000);
    };

    (window as any).showToast = showToast;
  }, []);

  return (
    <BootstrapToastContainer position="top-end" className="p-3">
      {toasts.map((toast) => (
        <Toast key={toast.id} bg={toast.type}>
          <Toast.Header>
            <strong className="me-auto"></strong>
          </Toast.Header>
          <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
      ))}
    </BootstrapToastContainer>
  );
};

export default ToastContainer;
