import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {notificationApi} from '../../../api/apis.configuration';
import {Notification} from '../../../api/notification-service-api';

interface DeleteNotificationModalProps {
  show: boolean;
  handleClose: () => void;
  notificationToDelete: Notification | null;
  handleNotificationDeleted: () => void;
}

const DeleteNotificationModal: React.FC<DeleteNotificationModalProps> = ({
                                                                           show,
                                                                           handleClose,
                                                                           notificationToDelete,
                                                                           handleNotificationDeleted
                                                                         }) => {
  const handleDelete = async () => {
    if (notificationToDelete) {
      try {
        await notificationApi.deleteNotification({id: notificationToDelete.id!});
        handleNotificationDeleted();
        handleClose();
      } catch (error) {
        console.error('Error deleting notification:', error);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Teade kustutamine</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Kas soovid kindlasti kustutada teate {notificationToDelete?.id}?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Katkesta
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Kustuta
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteNotificationModal;
