import {auditLogApi} from '../api/apis.configuration';
import {showToast} from '../services/toast.service';
import moment from 'moment';

export async function downloadZipFile(fileId: string) {
  try {
    auditLogApi.downloadAuditLogExport({fileId}, {
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream',
      }
    }).then((response) => {
      const blob = new Blob([response.data], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'audit-logs-' + moment().format('YYYY-MM-DD-HH-mm-ss') + '.zip');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  } catch (error) {
    showToast('danger', 'Error downloading audit logs');
  }
}
