/* tslint:disable */
/* eslint-disable */
/**
 * Notification Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Notification } from '../model';
// @ts-ignore
import type { OrderDirection } from '../model';
// @ts-ignore
import type { Problem } from '../model';
/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification: async (notification?: Notification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotification: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNotification', 'id', id)
            const localVarPath = `/api/v1/notification/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {OrderDirection} [orderDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNotifications: async (id?: Array<number>, page?: number, pageSize?: number, orderBy?: string, orderDirection?: OrderDirection, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDirection !== undefined) {
                localVarQueryParameter['orderDirection'] = orderDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNotification', 'id', id)
            const localVarPath = `/api/v1/notification/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification: async (id: number, notification?: Notification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNotification', 'id', id)
            const localVarPath = `/api/v1/notification/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotification(notification?: Notification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotification(notification, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.createNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotification(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotification(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.deleteNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<number>} [id] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {OrderDirection} [orderDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findNotifications(id?: Array<number>, page?: number, pageSize?: number, orderBy?: string, orderDirection?: OrderDirection, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findNotifications(id, page, pageSize, orderBy, orderDirection, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.findNotifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotification(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotification(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.getNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Notification} [notification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotification(id: number, notification?: Notification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotification(id, notification, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.updateNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {NotificationApiCreateNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification(requestParameters: NotificationApiCreateNotificationRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Notification> {
            return localVarFp.createNotification(requestParameters.notification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationApiDeleteNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotification(requestParameters: NotificationApiDeleteNotificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteNotification(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationApiFindNotificationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNotifications(requestParameters: NotificationApiFindNotificationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<Notification>> {
            return localVarFp.findNotifications(requestParameters.id, requestParameters.page, requestParameters.pageSize, requestParameters.orderBy, requestParameters.orderDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationApiGetNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification(requestParameters: NotificationApiGetNotificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<Notification> {
            return localVarFp.getNotification(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationApiUpdateNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotification(requestParameters: NotificationApiUpdateNotificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<Notification> {
            return localVarFp.updateNotification(requestParameters.id, requestParameters.notification, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createNotification operation in NotificationApi.
 * @export
 * @interface NotificationApiCreateNotificationRequest
 */
export interface NotificationApiCreateNotificationRequest {
    /**
     * 
     * @type {Notification}
     * @memberof NotificationApiCreateNotification
     */
    readonly notification?: Notification
}

/**
 * Request parameters for deleteNotification operation in NotificationApi.
 * @export
 * @interface NotificationApiDeleteNotificationRequest
 */
export interface NotificationApiDeleteNotificationRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationApiDeleteNotification
     */
    readonly id: number
}

/**
 * Request parameters for findNotifications operation in NotificationApi.
 * @export
 * @interface NotificationApiFindNotificationsRequest
 */
export interface NotificationApiFindNotificationsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof NotificationApiFindNotifications
     */
    readonly id?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof NotificationApiFindNotifications
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof NotificationApiFindNotifications
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof NotificationApiFindNotifications
     */
    readonly orderBy?: string

    /**
     * 
     * @type {OrderDirection}
     * @memberof NotificationApiFindNotifications
     */
    readonly orderDirection?: OrderDirection
}

/**
 * Request parameters for getNotification operation in NotificationApi.
 * @export
 * @interface NotificationApiGetNotificationRequest
 */
export interface NotificationApiGetNotificationRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationApiGetNotification
     */
    readonly id: number
}

/**
 * Request parameters for updateNotification operation in NotificationApi.
 * @export
 * @interface NotificationApiUpdateNotificationRequest
 */
export interface NotificationApiUpdateNotificationRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationApiUpdateNotification
     */
    readonly id: number

    /**
     * 
     * @type {Notification}
     * @memberof NotificationApiUpdateNotification
     */
    readonly notification?: Notification
}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {NotificationApiCreateNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public createNotification(requestParameters: NotificationApiCreateNotificationRequest = {}, options?: RawAxiosRequestConfig) {
        return NotificationApiFp(this.configuration).createNotification(requestParameters.notification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiDeleteNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public deleteNotification(requestParameters: NotificationApiDeleteNotificationRequest, options?: RawAxiosRequestConfig) {
        return NotificationApiFp(this.configuration).deleteNotification(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiFindNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public findNotifications(requestParameters: NotificationApiFindNotificationsRequest = {}, options?: RawAxiosRequestConfig) {
        return NotificationApiFp(this.configuration).findNotifications(requestParameters.id, requestParameters.page, requestParameters.pageSize, requestParameters.orderBy, requestParameters.orderDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiGetNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getNotification(requestParameters: NotificationApiGetNotificationRequest, options?: RawAxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getNotification(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationApiUpdateNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public updateNotification(requestParameters: NotificationApiUpdateNotificationRequest, options?: RawAxiosRequestConfig) {
        return NotificationApiFp(this.configuration).updateNotification(requestParameters.id, requestParameters.notification, options).then((request) => request(this.axios, this.basePath));
    }
}

