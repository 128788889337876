import {loginApi} from '../api/apis.configuration';
import {STORAGE_API_KEY, STORAGE_USER_KEY} from '../constants/storage.constants';
import AccountService from './account.service';


class AuthService {
  login(login: string, password: string) {
    return loginApi.login({loginRequest: {login, password}}).then(response => {
      if (response.data.apiKey) {
        sessionStorage.setItem(STORAGE_API_KEY, response.data.apiKey);
        window.location.href = '/';
      } else {
        throw new Error('No API key in response');
      }
      return response.data;
    })
  }

  logout() {
    sessionStorage.removeItem(STORAGE_API_KEY);
    sessionStorage.removeItem(STORAGE_USER_KEY);
    window.location.href = '/login';
  }

  isAuthenticated() {
    return !!sessionStorage.getItem(STORAGE_API_KEY);
  }

  isUserAdmin() {
    const user = this.getCurrentUser();
    return user && user.authorities?.includes('ROLE_ADMIN');
  }

  getCurrentUser() {
    const userStr = sessionStorage.getItem(STORAGE_USER_KEY);
    if (this.isAuthenticated() && !userStr) {
      return AccountService.getUserAccount().then(response => {
        return response;
      });
    }

    if (userStr) return JSON.parse(userStr);


    return null;
  }
}

const authService = new AuthService();

export default authService;
