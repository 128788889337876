import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const resources = {
  et: {
    translation: {
      "nav": {
        "appName": "MEDRE Proovitöö",
        "footer": "MEDRE Proovitöö 2024",
        "login": "Logi sisse",
        "users": "Kasutajad",
        "notifications": "Teated",
        "sendNotification": "Saada teade",
        "auditLogs": "Logiraamat",
        "logout": "Logi välja"
      },
      "login": {
        "title": "Sisselogimine",
        "username": "Kasutaja",
        "password": "Parool",
        "loginButton": "Logi sisse",
        "invalidCredentials": "Vale kasutajanimi või parool"
      },
      "homePage": {
        "title": "Proovitööna",
        "chapter1": "Proovitööna tuleb versioonihalduskeskkonnas esitada terviklik lahendus, mille\n                osadeks on töövood, taustteenuse kood, esitluskihi kood ja tulemi docker\n                konteiner. Lahendus peab olema dokumenteeritud.",
        "chapter2": "Proovitöö ülesandeks on luua lahendus, milles on kaks erinevat rolli. Rollideks on\n                kasutaja ja administraator. Eesmärk on võimaldada kasutajal saata teade kindla\n                sisuga ja administraatoril on võimalus teha salvestatud infole parandusi. Kõik\n                tegevused peavad olema administraatorile nähtavad, mis tegevusi teostati, kes\n                neid tegi ja mis olid muutused ehk administraatoril on logiraamat ja ta näeb kõik\n                tegevused mida teised administraatorid tegid, millal ja mis oli muudetud.\n                Administraatoril on ka võimalus teha väljavõtte logiraamatust. Väljavõtte ise on\n                ZIPitud (compressed) fail, mille sees on JSON ja kõik info, mis oli filtriga valitud, mida\n                administraator saab alla laadida. Gateway API-le on vaja ka lisada Swaggeri mille\n                sees on kirjeldatud kõik API kaudu tehtavad päringud."

      },
      "footer": {
        "title": "MEDRE Proovitöö 2024"
      }
    }
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'et',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
