import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {notificationApi} from '../../../api/apis.configuration';
import {Notification} from '../../../api/notification-service-api';
import moment from 'moment';
import {showToast} from '../../../services/toast.service';

interface NotificationModalProps {
  show: boolean;
  handleClose: () => void;
  handleNotificationSaved: () => void;
  isEdit?: boolean;
  notificationToEdit?: Notification | null;
}

const NotificationModal: React.FC<NotificationModalProps> = ({
                                                               show,
                                                               handleClose,
                                                               handleNotificationSaved,
                                                               isEdit = false,
                                                               notificationToEdit = null
                                                             }) => {
  const [dateTime, setDateTime] = useState<string>(new Date().toISOString().slice(0, 19));
  const [location, setLocation] = useState('');
  const [author, setAuthor] = useState('');
  const [licensed, setLicensed] = useState(false);
  const [comment, setComment] = useState('');
  const [validated, setValidated] = useState(false);
  const [authorValid, setAuthorValid] = useState(true);

  useEffect(() => {
    if (isEdit && notificationToEdit) {
      setDateTime(notificationToEdit.dateTime || '');
      setLocation(notificationToEdit.location || '');
      setAuthor(notificationToEdit.author || '');
      setLicensed(notificationToEdit.licensed || false);
      setComment(notificationToEdit.comment || '');
    } else {
      resetForm();
    }
  }, [isEdit, notificationToEdit]);

  const validateAuthor = (author: string) => {
    if (!author) return true;

    const authorRegex = /^\d{11}$/;
    return authorRegex.test(author);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;

    const isAuthorValid = validateAuthor(author);
    setAuthorValid(isAuthorValid);

    if (form.checkValidity() === false || !isAuthorValid) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    try {
      if (isEdit && notificationToEdit) {
        const updatedNotification: Notification = {
          id: notificationToEdit.id,
          dateTime: moment(dateTime).toISOString(),
          location,
          author,
          licensed,
          comment,
        };
        await notificationApi.updateNotification({
          id: notificationToEdit.id!,
          notification: updatedNotification
        }).then(() => {
          showToast('success', 'Teade muudetud');
          close();
        }).catch((error) => {
          showToast('danger', 'Teate muutmisel tekkis viga:' + JSON.stringify(error.response.data.fieldErrors));
        });
      } else {
        const newNotification: Notification = {
          dateTime: moment(dateTime).toISOString(),
          location,
          author,
          licensed,
          comment,
        };
        await notificationApi.createNotification({notification: newNotification}).then(() => {
          showToast('success', 'Teade saadetud');
          close();
        }).catch((error) => {
          showToast('danger', 'Teate saatmisel tekkis viga: ' + JSON.stringify(error.response.data.fieldErrors));
        });
      }


    } catch (error) {
      console.error('Error saving notification:', error);
    }
  };

  const close = () => {
    handleNotificationSaved();
    handleClose();
    resetForm();
  }

  const resetForm = () => {
    setDateTime('');
    setLocation('');
    setAuthor('');
    setLicensed(false);
    setComment('');
    setValidated(false);
    setAuthorValid(true);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? 'Muuda teade' : 'Teade saatmise vorm'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="formDateTime">
            <Form.Label>Päev ja kellaaeg</Form.Label>
            <Form.Control
              type="datetime-local"
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Palun sisestage kehtiv päev ja kellaaeg.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formLocation" className="mt-3">
            <Form.Label>Koht/Aadress</Form.Label>
            <Form.Control
              type="text"
              placeholder="Sisestage koht või aadress"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Palun sisestage kehtiv asukoht.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formAuthor" className="mt-3">
            <Form.Label>Isikukood</Form.Label>
            <Form.Control
              type="text"
              placeholder="Sisestage isikukood"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              isInvalid={!authorValid}
            />
            <Form.Control.Feedback type="invalid">
              Isikukood peab olema täpselt 11 numbrit
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formLicensed" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Litsents"
              checked={licensed}
              onChange={(e) => setLicensed(e.target.checked)}
            />
          </Form.Group>

          <Form.Group controlId="formComment" className="mt-3">
            <Form.Label>Kommentaar</Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              placeholder="Sisestage kommentaar"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-4">
            {isEdit ? 'Muuda' : 'Saada'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NotificationModal;
