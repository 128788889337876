import React, {useEffect, useState} from 'react';
import {Button, Pagination, Table} from 'react-bootstrap';
import {notificationApi} from '../../api/apis.configuration';
import {Notification} from '../../api/notification-service-api';
import NotificationModal from './modals/NotificationModal';
import DeleteNotificationModal from './modals/DeleteNotificationModal';
import {PAGE_SIZE} from '../../constants/page.constants';
import authService from '../../services/auth.service';

const NotificationList: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [notificationToEdit, setNotificationToEdit] = useState<Notification | null>(null);
  const [notificationToDelete, setNotificationToDelete] = useState<Notification | null>(null);

  const pageSize = PAGE_SIZE;

  const isAuthenticated = authService.isAuthenticated();

  useEffect(() => {
    fetchNotifications(currentPage, pageSize);

  }, [currentPage, pageSize]);

  const fetchNotifications = async (page: number, size: number) => {
    if (!isAuthenticated) {
      return;
    }

    try {
      const response = await notificationApi.findNotifications({
        page: page - 1,
        pageSize: size,
      });
      setNotifications(response.data);

      const totalCount = parseInt(response.headers['x-total-count'], 10);
      const totalPages = Math.ceil(totalCount / size);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleNotificationSaved = () => {
    fetchNotifications(currentPage, pageSize);
  };

  const handleNotificationDeleted = () => {
    fetchNotifications(currentPage, pageSize);
  };

  const renderPagination = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return <Pagination>{items}</Pagination>;
  };

  if (!isAuthenticated) {
    return (
      <div className="my-5">
        <h3>Teade saatmine</h3>
        <p>Eesmärk on võimaldada kasutajal saata teade kindla sisuga</p>
        <Button
          className="mt-3"
          variant="primary"
          onClick={() => {
            setIsEdit(false);
            setShowNotificationModal(true);
          }}
        >
          Ava teade saatmise vorm
        </Button>

        <NotificationModal
          show={showNotificationModal}
          handleClose={() => setShowNotificationModal(false)}
          handleNotificationSaved={handleNotificationSaved}
          isEdit={isEdit}
          notificationToEdit={notificationToEdit}
        />
      </div>
    );
  }

  return (
    <div className="my-5">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Teated</h3>
        <Button
          variant="primary"
          onClick={() => {
            setIsEdit(false);
            setShowNotificationModal(true);
          }}
        >
          Ava teade saatmise vorm
        </Button>
      </div>

      <Table striped bordered hover>
        <thead>
        <tr>
          <th>ID</th>
          <th>Päev ja kellaaeg</th>
          <th>Koht/Aadress</th>
          <th>Isikukood</th>
          <th>Litsents</th>
          <th>Kommentaar</th>
          <th></th>
        </tr>

        </thead>
        <tbody>
        {notifications.map((notification) => (
          <tr key={notification.id}>
            <td>{notification.id}</td>
            <td>{notification.dateTime}</td>
            <td>{notification.location}</td>
            <td>{notification.author}</td>
            <td>{notification.licensed ? 'Yes' : 'No'}</td>
            <td>{notification.comment}</td>
            <td>
              <Button
                variant="warning"
                size="sm"
                onClick={() => {
                  setNotificationToEdit(notification);
                  setIsEdit(true);
                  setShowNotificationModal(true);
                }}
                className="mx-1"
              >
                Edit
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => {
                  setNotificationToDelete(notification);
                  setShowDeleteModal(true);
                }}
                className="mx-1"
              >
                Delete
              </Button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-center">{renderPagination()}</div>

      <NotificationModal
        show={showNotificationModal}
        handleClose={() => setShowNotificationModal(false)}
        handleNotificationSaved={handleNotificationSaved}
        isEdit={isEdit}
        notificationToEdit={notificationToEdit}
      />

      <DeleteNotificationModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        notificationToDelete={notificationToDelete}
        handleNotificationDeleted={handleNotificationDeleted}
      />
    </div>
  );
};

export default NotificationList;
